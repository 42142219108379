import React, {useState} from 'react';
import {AUTH, getErrorMessage, post} from "../../services/Client";
import {Button, CssBaseline, TextField, Typography} from "@mui/material";
import Link from "@mui/material/Link";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import {LockOutlined} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {useHistory} from "react-router-dom";

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" href="https://nextome.net/">
				Nextome
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

export default function ForgotPasswordPage() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [code, setCode] = useState("");
	const [hasCode, setHasCode] = useState(false);
	const [checkPassword, setCheckPassword] = useState("");
	const [error, setError] = useState({});
	const [loading, setLoading] = useState(false);
	const classes = useStyles();
	const history = useHistory()

	function sendEmail() {
		if(!email) {
			setError({
				email: "Inserisci la tua email"
			})
			return
		}

		setLoading(true)
		post(`${AUTH}/password/forgotten/request`, {
			params: {email}
		})
			.then(() => setHasCode(true))
			.catch(e => setError({server: getErrorMessage(e)}))
			.finally(() => setLoading(false));

	}

	function submit() {
		let codeErr, passErr, checkErr;
		if (!code) codeErr = "Inserisci il codice che hai ricevuto via mail";
		if (!password) passErr = "Inserisci la nuova password";
		if (!checkPassword) checkErr = "Inserisci nuovamente la nuova password";

		if (codeErr || passErr || checkErr) {
			setError({
				code: codeErr,
				password: passErr,
				checkPassword: checkErr
			});
			return;
		}

		if (password !== checkPassword) {
			setError({
				checkPassword: "Le password non corrispondono"
			});
			return;
		}
		setLoading(true);
		post(`${AUTH}/password/forgotten/restore`, {
			body: {
				confirmationCode: code,
				email,
				newPassword:password
			}
		})
			.then(() => history.push("login"))
			.catch(e => setError({server: getErrorMessage(e)}))
			.finally(() => setLoading(false));
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline/>
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlined/>
				</Avatar>
				<Typography component="h2" variant="h5">
					Inserisci la tua email, ti invieremo un codice per ripristinare la password
				</Typography>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					name="email"
					label="Email"
					type="email"
					id="email"
					onChange={event => {
						setEmail(event.target.value);
						setError({});
					}}
					value={email}
					error={!!error.email}
					helperText={error.email}
				/>
				<Button
					fullWidth
					variant="contained"
					color="primary"
					className={classes.submit}
					onClick={sendEmail}
					disabled={loading}
				>
					Conferma
				</Button>

				{hasCode && <><TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					name="code"
					label="Codice"
					type="string"
					id="code"
					onChange={event => {
						setCode(event.target.value);
						setError({});
					}}
					value={code}
					error={!!error.code}
					helperText={error.code}
				/>
					<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					name="password"
					label="Password"
					type="password"
					id="password"
					onChange={event => {
						setPassword(event.target.value);
						setError({});
					}}
					value={password}
					error={!!error.password}
					helperText={error.password}
				/>
					<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					name="check-password"
					label="Ripeti Password"
					type="password"
					id="chkpassword"
					onChange={event => {
					setError({});
					setCheckPassword(event.target.value);
				}}
					value={checkPassword}
					error={!!error.checkPassword}
					helperText={error.checkPassword}
					/>
					<Button
					fullWidth
					variant="contained"
					color="primary"
					className={classes.submit}
					onClick={submit}
					disabled={loading}
					>
					Imposta
					</Button>
				</>
					}
			</div>
			<Box mt={4}>
				<div>{error.server}</div>
			</Box>
			<Box mt={8}>
				<Copyright/>
			</Box>
		</Container>
	);
}
