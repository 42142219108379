import {useQuery} from "react-query";
import {
	get, DEVICES, TREATMENTS, post, put, deleteElem, COMMANDS, CONFIGURATIONS, REGISTERS
} from "./Client";
import {useEffect, useState} from "react";
import {db} from "./db";
import {useLiveQuery} from "dexie-react-hooks";
import {roles} from "./ability";

export function syncronyzeDevices(user) {
	let url = user.role === roles.ADMIN.id ? DEVICES : `users/${user.id}/machines`
	get(url).then(data => db.devices.bulkPut(data))
}
export function syncronyzeConfigurations() {
	get("configurations").then(data => {
		db.configurations.clear()
		db.registers.clear()
		db.configurations.bulkAdd(data)
		data?.forEach(d => get(`configurations/${d.id}/registers`).then(r => db.registers.bulkAdd(r)))
	})
}

export function useGetDevices() {
	return useLiveQuery(() => db.devices.toArray())
}

export function useGetDevice(sn) {
	return useLiveQuery(() => db.devices.get({serialNumber: sn}))
}

export function saveDevice(device) {
	//Potrebbe essere un optimistic update, salvando a DB e poi eliminando in caso di errore
	let remote = device.id ? put(`${DEVICES}/${device.serialNumber}`, {body: device}) : post(`${DEVICES}`, {body: device})
	return remote.then(d => db.devices.put(d))
}

export function saveDeviceConfiguration(configuration) {
	//Potrebbe essere un optimistic update, salvando a DB e poi eliminando in caso di errore
	let remote = configuration.id ? put(`${CONFIGURATIONS}/${configuration.id}`, {body: configuration}) : post(`${CONFIGURATIONS}`, {body: configuration})
	return remote.then(d => db.configurations.put(d))
}

export function saveDeviceConfigurationRegister(register) {
	if(register.id)
		return put(`${CONFIGURATIONS}/${REGISTERS}/${register.id}`, {body: register}).then(d => db.registers.put(d))
	else
		return post(`${CONFIGURATIONS}/${register.configuration}/${REGISTERS}`, {body: [register]}).then(d => db.registers.bulkPut(d.registers))
}

export function deleteRegister(rid) {
	return deleteElem(`${CONFIGURATIONS}/${REGISTERS}/${rid}`).then(() => db.registers.delete(rid))
}

export function deleteDevice(device) {
	return deleteElem(DEVICES, {elem:device.serialNumber}).then(() => db.devices.delete(device.id))
}
export function deleteDeviceConfiguration(configuration) {
	return deleteElem(CONFIGURATIONS, {elem:configuration.id}).then(() => db.configurations.delete(configuration.id))
}

export function useGetDeviceHistory(sn) {
	const {status, data, error} = useQuery([TREATMENTS, {device: sn}], () => get(TREATMENTS, {params: {machineSerialNumber:sn}}));
	return {status, treatments: data || [], error};
}

export function updateDeviceHistory(id, body) {
	return put(`${TREATMENTS}/${id}`, {body})
}

export function useGetDeviceCommands(sn) {
	const {status, data, error} = useQuery([COMMANDS, {device: sn}], () => get(`${DEVICES}/${sn}/${COMMANDS}`));
	return {status, commands: data || [], error};
}

export function sendDeviceCommand(sn, body) {
	return post(`${DEVICES}/${sn}/${COMMANDS}`, {body})
}

export function useGetConfiguration(cid) {
	return  useLiveQuery(() => db.configurations.get(parseInt(cid)))
}

export function useGetConfigurations() {
	return  useLiveQuery(() => db.configurations.toArray())
}

export function useGetConfigurationRegisters(configuration) {
	return  useLiveQuery(() => db.registers.where('configuration').equals(parseInt(configuration)).toArray())
}

export function useGetConfigurationRegistersTable(configuration) {
	const [lookuptable, setLookuptable] = useState({})
	let registers = useLiveQuery(() => db.registers.where('configuration').equals(configuration).toArray())

	useEffect(() => {
		let table = {}
		registers?.forEach(r => table[r.number] = r)
		setLookuptable(table)
	}, [registers])

	return lookuptable
}
