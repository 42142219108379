import withStyles from '@mui/styles/withStyles';
import {Tooltip} from "@mui/material";
import React from "react";

export const SettingsTooltip = withStyles(theme => ({
    arrow: {
        color: theme.palette.primary.contrastText,
    },
    tooltip: {
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.text.primary,
    },
    tooltipPlacementTop: {
        marginBottom: "-0.6rem"
    },
}))(({ classes, title, children }) => (
    <Tooltip placement="top" classes={classes} title={title}>
        {children}
    </Tooltip>
));

export const ItemTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.primary.dark + 'dd',
    },
    tooltipPlacementRight: {
        marginLeft: "-0.5rem"
    },
    }))(({classes, title, children, enabled }) => {
        if(enabled) return (<Tooltip classes={classes} placement="right" title={title}>
                                {children}
                            </Tooltip>)
        else return <>{children}</>
    });
