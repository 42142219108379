import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {Button, DialogActions} from "@mui/material";

const useStyles = makeStyles((theme) => ({
	root: {
		color:theme.palette.primary.contrastText,
		"&::-webkit-scrollbar": {
			width: 5,
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: theme.palette.primary.contrastText,
			borderRadius: "10px"
		},
	},
}));

export default function ConfirmModal({open, onClose, onSave, children, title, fullWidth, closeText="Annulla", saveText="Conferma"}) {

	const classes = useStyles();

	return (
        <Dialog open={open} onClose={onClose} fullWidth={fullWidth} maxWidth={fullWidth && "lg"}
				sx={{
					"& .MuiPaper-root": {
						backgroundColor: "primary.dark",
						borderRadius:5,
						maxHeight: "80vh"
					}
				}}>
			<DialogTitle sx={{color:"primary.contrastText",textAlign: "center", textTransform:"uppercase"}}>
				{title}
			</DialogTitle>
			<DialogContent className={classes.root}>
				{children}
			</DialogContent>
	        <DialogActions>
		        <Button sx={{color:"white"}} onClick={onClose}>{closeText}</Button>
		        <Button color={"error"} onClick={onSave}>{saveText}</Button>
	        </DialogActions>
		</Dialog>
    );
}
