import React, {useState} from 'react';
import {AUTH, getErrorMessage, post} from "../../services/Client";
import {Button, CssBaseline, TextField, Typography} from "@mui/material";
import Link from "@mui/material/Link";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import {LockOutlined} from "@mui/icons-material";
import Box from "@mui/material/Box";

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" href="https://nextome.net/">
				Nextome
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

export default function ChangePasswordPage({onSuccess, email}) {
	const [password, setPassword] = useState("");
	const [oldPassword, setOldPassword] = useState("");
	const [checkPassword, setCheckPassword] = useState("");
	const [error, setError] = useState({});
	const [loading, setLoading] = useState(false);
	const classes = useStyles();

	function submit() {
		let oldPassErr, passErr, checkErr;
		if (!oldPassword) oldPassErr = "Please insert the old password";
		if (!password) passErr = "Please insert a new password";
		if (!checkPassword) checkErr = "Please repeat password";

		if (oldPassErr || passErr || checkErr) {
			setError({
				oldPassword: oldPassErr,
				password: passErr,
				checkPassword: checkErr
			});
			return;
		}

		if (password !== checkPassword) {
			setError({
				checkPassword: "Passwords mismatch"
			});
			return;
		}
		setLoading(true);
		post(`${AUTH}/password/challenge`, {
			body: { email, oldPassword, newPassword:password}
		})
			.then(onSuccess)
			.catch(e => setError({server: getErrorMessage(e)}))
			.finally(() => setLoading(false));
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline/>
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlined/>
				</Avatar>
				<Typography component="h2" variant="h5">
					Inserisci una nuova password. Tu sarai l'unico a conoscere quella nuova
				</Typography>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					name="old-password"
					label="Old Password"
					type="password"
					id="old-password"
					onChange={event => {
						setOldPassword(event.target.value);
						setError({});
					}}
					value={oldPassword}
					error={!!error.oldPassword}
					helperText={error.oldPassword}
				/>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					name="password"
					label="Password"
					type="password"
					id="password"
					onChange={event => {
						setPassword(event.target.value);
						setError({});
					}}
					value={password}
					error={!!error.password}
					helperText={error.password}
				/>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					name="check-password"
					label="Repeat Password"
					type="password"
					id="chkpassword"
					onChange={event => {
						setError({});
						setCheckPassword(event.target.value);
					}}
					value={checkPassword}
					error={!!error.checkPassword}
					helperText={error.checkPassword}
				/>
				<Button
					fullWidth
					variant="contained"
					color="primary"
					className={classes.submit}
					onClick={submit}
					disabled={loading}
				>
					Imposta
				</Button>
			</div>
			<Box mt={4}>
				<div>{error.server}</div>
			</Box>
			<Box mt={8}>
				<Copyright/>
			</Box>
		</Container>
	);
}
