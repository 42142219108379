
import React, {useContext, useState} from "react";

import {Paper} from "@mui/material";
import {GridActionsCellItem
} from "@mui/x-data-grid"
import NTMXGrid from "../components/NTMXGrid";
import {useGetUsers} from "../services/UserDataManager";
import FilterAndSearchBar from "../components/layout/FilterAndSearchBar";
import {getRoleList} from "../services/ability";
import {UserContext} from "./App";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import UserModal from "../components/modals/UserModal";
import DeleteUserModal from "../components/modals/DeleteUserModal";
import {Delete} from "@mui/icons-material";

export function UserManager() {

    const users = useGetUsers() || []
    const user = useContext(UserContext);
    const [createModal, setCreateModal] = useState(false)
    const [filter, setFilter] = useState(null)
    const [search, setSearch] = useState()
    const [editUser, setEditUser] = useState()
    const [deleteUser, setDeleteUser] = useState()

    let data
    if(filter) data = users.filter(u => u.role==filter)
    else data = users.slice()

    if(search) data = data.filter(u => u.name.toLocaleLowerCase().includes(search) ||
        u.surname.toLocaleLowerCase().includes(search) || u.email.toLocaleLowerCase().includes(search))

    return <Paper style={{padding: "2rem"}}>
        <FilterAndSearchBar
            allButton
            allText={"Tutti"}
            filters={getRoleList(user.data)}
            selected={filter}
            onSearch={setSearch}
            onFilter={setFilter}
            addText={"Aggiungi nuovo utente"}
            addElement={() => setCreateModal(true)}
        />
        <NTMXGrid
            disableToolbar
            columns={[
                {
                    field: "email",
                    headerName: "Email",
                    flex:1,
                },
                {
                    field: "name",
                    headerName: "Nome",
                    flex:1,
                },
                {
                    field: "surname",
                    headerName: "Cognome",
                    flex:1,
                },
                {
                    field: "role",
                    headerName: "Ruolo",
                    flex:1,
                    valueGetter: ({value}) => getRoleList(user.data).find(r => r.id == value)?.name
                },
                {
                    field: 'actions',
                    type: 'actions',
                    getActions: ({row}) => {
                        return [
                            <GridActionsCellItem icon={<ModeEditIcon color={"primary"}/>}
                                                 onClick={() => setEditUser(row)} label="Edit"/>,
                            <GridActionsCellItem icon={<Delete color={"primary"}/>}
                                                 onClick={() => setDeleteUser(row)} label="Delete"/>,
                        ];
                    }
                }
            ]}
            rows={data}
            title="Elenco Utenti"
            getRowId={(row) => users && row.id}
        />
        <UserModal open={editUser || createModal} onClose={() => {
            setEditUser(null)
            setCreateModal(false);
        }} user={editUser}/>

        <DeleteUserModal open={!!deleteUser} onClose={() => setDeleteUser(undefined)} user={deleteUser}/>
    </Paper>
}
