import React, {useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import Grid from "@mui/material/Grid";

const TextInput = React.forwardRef((props, ref) => {

    const {
        autoComplete = 'new-password',
        autoFocus,
        children,
        className,
        color = 'light',
        disabled,
        error,
        fullWidth = true,
        helperText,
        InputLabelProps,
        InputProps,
        inputProps,
        inputRef,
        label,
        min,
        max,
        multiline,
        noHelperText,
        onBlur,
        onClick,
        onFocus,
        onTextChange,
        readOnly,
        required,
        rows,
        select,
        showPasswordIcon,
        startAdornment,
        step,
        type = "text",
        value
    } = props

    const useStyles = makeStyles(theme => ({
        root: {
            '& label': {
                color: color === 'light' ? theme.palette.secondary.light : theme.palette.primary.main,
                fontStyle: "italic",
                '&.MuiInputLabel-shrink': {
                    fontStyle: "normal"
                },
                '&.Mui-focused': {
                    color: color === 'light' ? theme.palette.secondary.main : theme.palette.primary.main,
                },
                '&:hover': {
                    color: color === 'light' ? theme.palette.secondary.main : theme.palette.primary.main,
                },
            },
            '& .MuiOutlinedInput-root': {
                borderRadius: 17,
                '& fieldset': {
                    borderColor: color === 'light' ? theme.palette.secondary.light : theme.palette.primary.main,
                },
                '&:hover fieldset': {
                    borderColor: color === 'light' ? theme.palette.secondary.main : theme.palette.primary.main,
                },
                '&.Mui-focused fieldset': {
                    borderColor: color === 'light' ? theme.palette.secondary.main : theme.palette.primary.main,
                    borderWidth: "3px"
                },
            },
            "& .Mui-error": {
                color: color === 'light' ? theme.palette.error.light : theme.palette.error.main,
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: color === 'light' ? theme.palette.error.light : theme.palette.error.main,
                }
            },
            "& .MuiOutlinedInput-input": {
                color: color === 'light' ? theme.palette.secondary.light : theme.palette.primary.main,
                '&:hover': {
                    color: color === 'light' ? theme.palette.secondary.main : theme.palette.primary.dark,
                }
            }
        },
        input: {
            color: color === 'light' ? theme.palette.secondary.main : theme.palette.primary.contrastText,
            '&:focused': {
                borderColor: theme.palette.secondary.main,
            },
            '&:hover': {
                color: theme.palette.secondary.main,
            },
            '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: theme.palette.primary.contrastText,
                opacity: 1 /* Firefox */
            },
            '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
                color: theme.palette.primary.contrastText,
            },
            '&::-ms-input-placeholder': { /* Microsoft Edge */
                color: theme.palette.primary.contrastText,
            }
        },
        colorGrey: {
            color: 'rgba(0, 0, 0, 0.54)'
        }
    }));

    const classes = useStyles();
    let [showPassword, setShowPassword] = useState(false);
    const preventLoseFocus = (event) => {
        event.preventDefault()
    }
    return (
        <TextField
            {...inputProps}
            className={className}
            variant="outlined"
            fullWidth={fullWidth}
            classes={{root: classes.root}}
            label={label}
            value={value}
            sx={{
                "& .MuiOutlinedInput-input": {
                    color: color === 'light' ? "primary.light" : "text.primary",
                    '&:hover': {
                        color: "secondary.main",
                    }
                },
                "& .MuiInputBase-root": {
                    margin: 0
                }
            }}
            inputProps={{
                className: classes.input,
                readOnly,
                type: showPassword ? "text" : type,
                step, min, max,
                autoComplete: autoComplete,
            }}
            InputProps={InputProps || {
                endAdornment: (showPasswordIcon &&
                    <InputAdornment position="end">
                        {showPassword ?
                            <Visibility onClick={() => setShowPassword(false)} onMouseUp={preventLoseFocus}
                                        onMouseDown={preventLoseFocus}/> :
                            <VisibilityOff onClick={() => setShowPassword(true)} onMouseUp={preventLoseFocus}
                                           onMouseDown={preventLoseFocus}/>}
                    </InputAdornment>),
                startAdornment
            }}
            onChange={({target}) => onTextChange(target.value)}
            required={required}
            multiline={multiline}
            rows={rows}
            error={!!error}
            helperText={
                !noHelperText && (
                    <>
                        {
                            helperText &&
                            <span className={classes.colorGrey}>{helperText}</span>
                        } {helperText && <br/>} {error || ''}
                    </>
                )
            }
            select={select}
            InputLabelProps={InputLabelProps}
            onClick={onClick}
            autoFocus={autoFocus}
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            inputRef={inputRef}
        >
            {children}
        </TextField>
    )
})

export function TextInputContainer(props) {
    return <Grid item xs={props.xs} style={{padding: "16px"}}>
        <TextInput
            {...props}
        >
            {props.children}
        </TextInput>
    </Grid>
}

export default TextInput
