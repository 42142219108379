
import {TextInputContainer} from "../forms/TextInput";
import BaseModal from "./BaseModal";
import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {getErrorMessage, TREATMENTS} from "../../services/Client";
import {updateDeviceHistory} from "../../services/DeviceDataManager";
import {useQueryClient} from "react-query";

export default function HistoryNoteModal({open, onClose, treatment}){

    let [values, setValues] = useState(treatment || {})
    let [error, setError] = useState({})
    const {enqueueSnackbar} = useSnackbar()
    const queryClient = useQueryClient()

    useEffect(() => {
        if(treatment) setValues(treatment)
    }, [treatment])

    const close = () => {
        setValues({});
        setError({});
        onClose();
    }

    const save = () => {
        setError({})
        updateDeviceHistory(values.id, {details:values.details})
            .then(() => {
                enqueueSnackbar("Salvato", {variant: "success"})
                queryClient.invalidateQueries([TREATMENTS, {device: treatment.machine}])
                close()
            })
            .catch(e => setError({general:getErrorMessage(e)}))
    }

    const onTextChange = (details) => {
        setError({});
        setValues({
            ...treatment,
            details
        });
    }

    return <BaseModal open={open} onClose={close} onSave={save} error={error.general}>
        <TextInputContainer
            multiline rows={5} label={"Note"} value={values.details} error={error.details}
            onTextChange={(value) => {onTextChange(value)}}
        />
    </BaseModal>
}
