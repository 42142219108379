import {Grid,Paper,Typography} from "@mui/material";
import {useHistory} from "react-router-dom";
import StartIconButton from "../buttons/StartIconButton";
import logo from "../../images/logo.png";
import React from "react";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        position: "fixed",
        minHeight: '100%',
        minWidth: '100%'
    },
    logo: {
        height : '7rem',
    },
    paper: {
        height: '80vh',
        width: '80vw'
    },
    content: {
        padding: theme.spacing(3),
        minHeight: '80%',
        minWidth: '100%'
    },
    title: {
        padding: theme.spacing(3),
        fontWeight: 600
    },
    username: {
        color: theme.palette.primary.main
    }
}));

export default function ResetPassword({username}) {
    let history = useHistory();
    const classes = useStyles();

    return <Grid container justify="center" alignItems="center" className={classes.root}>
        <Paper className={classes.paper} elevation={5}>
            <Grid container justify="center" alignItems="center" className={classes.content} direction="column">

                <img className={classes.logo} alt={"home"} src={logo}/>

                <Grid item className={classes.title}>
                    <Typography variant={'h4'} align="center">Ripristino password</Typography>
                </Grid>

                <Grid>
                    <Typography variant={'h5'} align="center">
                        Abbiamo inviato una email {username && <> a <span className={classes.username}>{username}</span></>} per il ripristino password.
                    </Typography>
                </Grid>

                <Grid>
                    <Typography variant={'h6'} color="secondary" align="center">
                        Dopo aver ricevuto l'email segui le istruzioni fornite per aggiornare la tua password.
                    </Typography>
                </Grid>

                <Grid>
                    <StartIconButton title="Torna al login" onClick={() => history.goBack()} size="large"/>
                </Grid>

            </Grid>
        </Paper>
    </Grid>
}
