
import BaseModal from "./BaseModal";
import React, {useContext, useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {deleteUser, useGetUsersBySupervisor} from "../../services/UserDataManager";
import {getErrorMessage} from "../../services/Client";
import {UserContext} from "../../containers/App";
import {roles} from "../../services/ability";
import {Typography} from "@mui/material";

export default function DeleteUserModal({open, onClose, user}){

    let [error, setError] = useState({})
    let [canDelete, setCanDelete] = useState(true)
    const loggedUser = useContext(UserContext);
    const supervisors = useGetUsersBySupervisor(user?.id)
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        setCanDelete(!(loggedUser.data.role === roles.SUPERVISOR.id && supervisors?.length > 0))
    }, [user, supervisors])

    const close = () => {
        setError({});
        onClose();
    }

    const save = () => {
        setError({})

        deleteUser(user)
            .then(() => {
                enqueueSnackbar("Eliminato", {variant: "success"})
                close()
            })
            .catch(e => setError({general:getErrorMessage(e)}))
    }

    return <BaseModal open={open} onClose={close} onSave={save} error={error.general}>
        {
            canDelete ?
                <Typography>Confermi di voler eliminare l'utente selezionato?</Typography> :
            <Typography>Non è possibile eliminare l'utente selezionato perché esistono ancora dei medici sottoposti</Typography>
        }
    </BaseModal>
}
