import React, {useState} from "react";
import BaseModal from "./BaseModal";
import {useSnackbar} from "notistack";
import {COMMANDS, getErrorMessage} from "../../services/Client";
import { Grid} from "@mui/material";
import {sendDeviceCommand, useGetConfigurationRegisters} from "../../services/DeviceDataManager";
import {useQueryClient} from "react-query";
import NTMSelect from "../NTMSelect";
import TextInput from "../forms/TextInput";
import dayjs from "dayjs";

let utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default function SendCommandModal({open, onClose, configuration, sn, lastCommand}){

    let registers = useGetConfigurationRegisters(configuration||1) || []
    let [values, setValues] = useState({})
    let [error, setError] = useState({})
    let [disabled, setDisabled] = useState(false)
    const {enqueueSnackbar} = useSnackbar()
    let queryClient = useQueryClient();

    const close = () => {
        setValues({})
        setError({});
        onClose();
    }

    const save = () => {
        // let interval = dayjs().utc().diff(dayjs.utc(lastCommand), 'minute')
        // if(interval < 5) {
        //     setDisabled(true)
        //     setError({general: `Sono passati meno di 5 minuti dall'ultimo comando, attendi altri ${5-interval} minuti prima di inviarne un altro`})
        //     return
        // }

        setDisabled(false)
        setError({})
        if(Object.keys(values).length === 0) close()
        else sendDeviceCommand(sn, Object.entries(values).map(e => ({register:parseInt(e[0]), value:e[1]})))
            .then(() => {
                enqueueSnackbar("Salvato", {variant: "success"})
                close()
            })
            .catch(e => setError({general:getErrorMessage(e)}))
            .finally(() => queryClient.invalidateQueries([COMMANDS, {device: sn}]));
    }

    const onChange = (name, value) => {
        let newValues = {...values};
        setError({});
        newValues[name] = value;
        setValues(newValues);
    }

    return <BaseModal open={open} onClose={close} onSave={save} error={error.general}>

        <Grid item style={{padding: "16px", minHeight: "15rem", minWidth: "30rem"}}>
            {
                registers.filter(r => r.writable).map(r => {
                    if(r.type === 1){
                        return <Grid item sm={12} style={{padding: 8}}>
                            <NTMSelect
                                label={r.name}
                                items={r.values?.map(d => ({value: d.value, text: d.name})) || []}
                                onChange={value => onChange(r.number, value)}
                                value={values[r.number] === 0 ? 0 : values[r.number] || ""}
                                disabled={disabled}
                            />
                        </Grid>
                    } else return (<Grid item sm={12} style={{padding: 8}}>
                        <TextInput
                            label={r.name}
                            value={values[r.number] === 0 ? 0 : values[r.number] || ""}
                            onTextChange={value => onChange(r.number, value)}
                            error={error[r.number]}
                            type = "number"
                            step={r.rangeStep}
                            min={r.rangeMin}
                            max={r.rangeMax}
                            onBlur={() => onChange(r.number, Math.min(~~(Math.max(values[r.number], r.rangeMin) / r.rangeStep) * r.rangeStep, r.rangeMax))}
                            disabled={disabled}
                        />
                    </Grid>)
                })
            }
        </Grid>
    </BaseModal>
}
