import React, {useState} from "react";

import {
    Button,
    Card,
    CardActionArea,
    CardContent,
    Typography, Grid
} from "@mui/material";
import {useGetConfigurations} from "../services/DeviceDataManager";
import {useHistory} from "react-router-dom";
import AddIcon from '@mui/icons-material/AddCircle';
import Container from "@mui/material/Container";
import {useTheme} from "@mui/material/styles";
import Can from "../services/Can";
import {abilities, resources} from "../services/ability";
import DeviceModelModal from "../components/modals/DeviceModelModal";

let DeviceItem = ({id, name}) => {
    let [hover, setHover] = useState(false)
    const history = useHistory()
    const theme = useTheme();

    return <Grid key={id} sm={6} item> <Card
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        sx={{
            backgroundColor:'primary.main',
            borderRadius: 0,
            color:'primary.contrastText',
            '&:hover': {
                bgcolor: theme.palette.secondary.main
            }
        }}>
        <CardActionArea onClick={() => history.push(`configurations/${id}`)}>
            <CardContent>
                <Typography variant="body2" color="text.light">
                    {name}
                </Typography>
            </CardContent>
        </CardActionArea>
    </Card></Grid>
}

export function ConfigurationManagement() {

    const configurations = useGetConfigurations()
    const [createModal, setCreateModal] = useState(false)

    return <Container>
        <Grid container direction={"row"} spacing={2} alignItems={'center'}>
            <Grid item sm={4}/>
            <Grid item sm={4}>
                <Can I={abilities.WRITE} a={resources.CONFIGURATIONS}>
                    <Button color={"primary"} startIcon={<AddIcon />} onClick={() => setCreateModal(true)}>{"Aggiungi nuovo modello"}</Button>
                </Can>
            </Grid>
            <Grid item sm={4}></Grid>
        </Grid>

        <Grid container item spacing={2} sx={{marginTop:"1rem"}}>
            {
                configurations?.map(d => <DeviceItem key={d.id} id={d.id} name={d.name} />)
            }
        </Grid>
        <DeviceModelModal open={createModal} onClose={() => setCreateModal(false)}/>
    </Container>

}
