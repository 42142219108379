import React, {useEffect, useState} from "react";
import {TextInputContainer} from "../forms/TextInput";
import BaseModal from "./BaseModal";
import {deleteRegister, saveDeviceConfigurationRegister} from "../../services/DeviceDataManager";
import {getErrorMessage} from "../../services/Client";
import {useSnackbar} from "notistack";
import {Button, Checkbox, FormControlLabel, Grid} from "@mui/material";
import RadioButtons from "../forms/RadioButtons";
import AddIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from '@mui/icons-material/Delete';

const form = [
    {label:"Nome", field: "name"},
    {label:"Numero registro", field: "number", type:"number"},
]

export default function DeviceRegistryModal({open, onClose, register, cid}){

    let [values, setValues] = useState(register || {type:1, writable:false});
    let [error, setError] = useState({});
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        if(register) setValues(register)
    }, [register])

    const close = () => {
        setValues({});
        setError({});
        onClose();
    }

    const save = () => {
        setError({})
        values.configuration=values.configuration||cid
        saveDeviceConfigurationRegister(values)
            .then(() => {
                enqueueSnackbar("Salvato", {variant: "success"})
                close()
            })
            .catch(e => setError({general:getErrorMessage(e)}))
    }

    const onValueChange = (value, name, index) => {
        let newValues = (values.values || []).slice();
        newValues[index][name] = value;
        onChange(newValues, "values")
    }

    const onValueAdd = () => {
        let newValues = (values.values || []).slice();
        newValues.push({
            name:"",
            value:0
        })
        onChange(newValues, "values")
    }

    const onChange = (value, name) => {
        let newValues = {...values};
        setError({});
        newValues[name] = value;
        setValues(newValues);
    }

    const onDelete = () => {
        deleteRegister(register.id)
        close()
    }

    return <BaseModal open={open} onClose={close} onSave={save} error={error.general}
                      iconButton={register && <DeleteIcon sx={{color:"brown"}}/>}
                      onClickButton={onDelete}>
            {
                form.map(({field, label, type="string", readOnly=false}) =>
                    <TextInputContainer
                        xs={12} label={label} key={field} value={values[field]} type={type} error={error[field]}
                        disabled={readOnly && values.id} onTextChange={(value) => {onChange(value, field)}}
                    />)
            }
        <Grid item sx={{padding: "16px"}}>
            <FormControlLabel control={<Checkbox
                sx={{color: 'white', '&.Mui-checked': {color: 'white'}}} checked={values.writable}
                onChange={({target}) => onChange(target.checked, 'writable')}
                inputProps={{'aria-label': 'controlled'}}/>}
                              label={"Registro in scrittura"}
            />
        </Grid>
        <Grid item sx={{padding: "16px"}}>
            <RadioButtons
                value={values.type} label={"Tipo valore"}
                onChange={(value) => {
                    onChange(parseInt(value), "type");
                }}
                options={[
                    {
                        label: "Multiselezione",
                        value: 1
                    },
                    {
                        label: "Range di valori",
                        value: 2
                    },
                ]}
            />
        </Grid>
        {
            (values.type || 1) === 1 ?
                <Grid item sx={{padding: "16px"}}>
                    {values.values?.map((v, i) =>
                        <Grid container item spacing={2} direction={"row"} key={i}>
                            <TextInputContainer
                                xs={6} label={"Nome"} value={v.name} type={"string"}
                                onTextChange={(value) => onValueChange(value, "name", i)} color={"light"}
                            />
                            <TextInputContainer
                                xs={6} label={"Valore"} value={v.value} type={"string"}
                                onTextChange={(value) => onValueChange(value, "value", i)} color={"light"}
                            />
                        </Grid>
                    )}
                    <Button sx={{color:"white"}} startIcon={<AddIcon/>} onClick={onValueAdd}>{"Aggiungi"}</Button>
                </Grid>
                :
                <Grid container item spacing={2} direction={"row"}>
                    <TextInputContainer
                        xs={4} label={"Min"} value={values.rangeMin} type={"number"}
                        onTextChange={(value) => {
                            onChange(value, "rangeMin");
                        }}
                        color={"light"}
                    />
                    <TextInputContainer
                        xs={4} label={"Max"} value={values.rangeMax} type={"number"}
                        onTextChange={(value) => {
                            onChange(value, "rangeMax");
                        }}
                        color={"light"}
                    />
                    <TextInputContainer
                        xs={4} label={"Step"} value={values.rangeStep} type={"number"}
                        onTextChange={(value) => {
                            onChange(value, "rangeStep");
                        }}
                        color={"light"}
                    />
                </Grid>
        }
    </BaseModal>
}
