import Cookies from 'universal-cookie';

const cookies = new Cookies();
const tokenName = `JWT-BCON-ACC-${process.env.REACT_APP_ENVIRONMENT}`

export function saveToken(token) {
	cookies.remove(tokenName);
	cookies.set(tokenName, token, {path:"/"});
}

export function getToken() {
	return cookies.get(tokenName);
}

export function deleteToken() {
	cookies.remove(tokenName);
}
