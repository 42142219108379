import Dexie from 'dexie';
Dexie.debug = true;

export const db = new Dexie('bconnect');

db.version(3).stores({
	users: 'id, email, role, supervisor',
	devices: 'id, &serialNumber,doctors.id, doctors.email',
	patients: 'id, fiscalCode',
	registers:'id, configuration',
	configurations:'id',
});

