import BaseModal from "./BaseModal";
import {TextInputContainer} from "../forms/TextInput";
import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import {Visibility, VisibilityOff} from "@mui/icons-material";


export default function ChangeCredentialsModal({open, onClose}) {

    let [newEmail, setNewEmail] = useState("");
    let [currentPassword, setCurrentPassword] = useState("");
    let [newPassword, setNewPassword] = useState("");
    let [repeatPassword, setRepeatPassword] = useState("");

    let [error, setError] = useState({});

    let [showPasswords, setShowPasswords] = useState(false);

    const save = () => {

        if (currentPassword === "") {
            setError({old: "Campo obbligatorio"});
            return;
        }
        if (newPassword !== repeatPassword) {
            setError({repeat: "Le password non coincidono", new: "Le password non coincidono"});
            return;
        }

        close()
    }

    const close = () => {
        setNewEmail("");
        setCurrentPassword("");
        setNewPassword("");
        setRepeatPassword("");
        setError({});
        onClose();
    }

    return (
        <BaseModal open={open} onClose={close} onSave={save}
                   iconButton={showPasswords ? <VisibilityOff/> : <Visibility/>}
                   onClickButton={() => setShowPasswords(!showPasswords)}>
            <Grid container spacing={4} style={{margin: 0, width: "100%", marginBottom: "1.5rem"}}>

                    <TextInputContainer xs={6} label={"Nuova Email"} value={newEmail}
                               onTextChange={(value) => {
                                   setNewEmail(value.trim());
                                   setError({});
                               }} error={error.email}/>

                    <TextInputContainer xs={6} required label={"Password Attuale"} value={currentPassword}
                               type={showPasswords ? "text" : "password"}
                               onTextChange={(value) => {
                                   setCurrentPassword(value.trim());
                                   setError({});
                               }} error={error.old}/>

                    <TextInputContainer xs={6} label={"Nuova Password"} value={newPassword} type={showPasswords ? "text" : "password"}
                               onTextChange={(value) => {
                                   setNewPassword(value.trim());
                                   setError({});
                               }} error={error.new}/>

                    <TextInputContainer xs={6} label={"Ripeti Password"} value={repeatPassword}
                               type={showPasswords ? "text" : "password"}
                               onTextChange={(value) => {
                                   setRepeatPassword(value.trim());
                                   setError({});
                               }} error={error.repeat}/>

            </Grid>
        </BaseModal>
    )

}
