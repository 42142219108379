import React, {useContext} from "react";

import {Link} from "react-router-dom";
import clsx from "clsx";

import makeStyles from '@mui/styles/makeStyles';
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import {UserContext} from "../../containers/App";
import HoverableButton from "./HoverableButton";
import HoverableProfileButton from "./HoverableProfileButton";

import logo from "../../images/logo.png"

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: theme.palette.primary.main,
        height: "4rem",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        // width: `calc(100% - ${drawerWidth}px)`,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(
            ['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
    },
    menuButton: {
        color: theme.palette.primary.dark,
        [theme.breakpoints.up('md')]: {
            marginRight: theme.spacing(4),
        }
    },
    hide: {
        display: 'none',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    logo: {
        height: '3.5rem',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: 'unset',
            maxWidth: 100
        }
    },
    text: {
        color: theme.palette.primary.contrastText,
        padding: 0
    }
}));

export default function Header({menuOpen, toggleMenu, noMenu = false}) {
    const classes = useStyles();
    const user = useContext(UserContext);

    return (
        <AppBar
            position="fixed"
            color="secondary"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: menuOpen,
            })}
        >
            <Toolbar>
                {
                    !noMenu &&
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => toggleMenu(!menuOpen)}
                        edge="start"
                        className={clsx(classes.menuButton)}
                        size="large">
                        <MenuIcon/>
                    </IconButton>

                }
                <div style={{flexGrow: 0.023}}/>
                <Link to="/">
                    <img className={classes.logo} alt={"home"} src={logo}/>
                </Link>
                <div style={{flexGrow: 1}}/>
                <Link to={"/profile/"+user.data.id} style={{textDecoration: "none"}}>
                    {
                        <HoverableProfileButton
                            item={{
                                name: `${user.data.name} ${user.data.surname}` || "Guest",
                                icon: <PersonIcon/>,
                                avatarUrl: user.data.avatarUrl
                            }}
                        />}
                </Link>
                <div style={{flexGrow: 0.01}}/>
                <HoverableButton
                    item={{
                        name: "LogOut",
                        icon: <ExitToAppIcon sx={{ color: "#fff"}}/>
                    }}
                    onClick={user.onLogout}
                    classes={{text: classes.text}}
                />
            </Toolbar>
        </AppBar>
    );
}
