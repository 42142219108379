import {Ability, AbilityBuilder} from "@casl/ability";
import {PATIENTS} from "./Client";

const roles = {
    USER: {
        id:3,
        name:"Operatore"
    },
    SUPERVISOR: {
        id: 2,
        name: "Responsabile"
    },
    ADMIN: {
        id:1,
        name: "Superutente"
    }
};

const abilities = {
    WRITE: "write",
    READ: "read"
}

const resources = {
    DEVICES: "devices",
    USERS: "users",
    PATIENTS: "patients",
    CONFIGURATIONS: "configurations",
};

function defineRulesFor(user) {

    const { can, cannot, build} = new AbilityBuilder(Ability)
    if (user.role === roles.ADMIN.id) {
        can(abilities.WRITE, "all")
        can(abilities.READ, "all")
        cannot(abilities.READ, PATIENTS)
    }
    if(user.role === roles.SUPERVISOR.id){
        can(abilities.READ, "all")
        can(abilities.WRITE, "all")
        cannot(abilities.WRITE, resources.DEVICES)
        cannot(abilities.READ, resources.CONFIGURATIONS)
    }
    return build()
}

function getRoleList(user) {
    let out = [roles.USER, roles.SUPERVISOR]
    if(user.role === roles.ADMIN.id) out.push(roles.ADMIN)
    return out
}

export default function ability(user) {
    return defineRulesFor(user || {});
}

export {
    roles,
    resources,
    abilities,
    getRoleList
}
