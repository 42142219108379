import {useEffect, useState} from "react";

import {
	deleteElem,
	get, PATIENTS, post, put,
} from "./Client";
import {db} from "./db";
import {useLiveQuery} from "dexie-react-hooks";


export function syncronyzePatients() {
	get(PATIENTS).then(async data => {
			await db.patients.clear()
			db.patients.bulkPut(data)
				.catch(console.log);
		})
}

export function useGetPatients() {
	return useLiveQuery(() => db.patients.toArray())
}

export function savePatient(patient) {
	let remote = patient.id ? put(`${PATIENTS}/${patient.id}`, {body: patient}) : post(`${PATIENTS}`, {body: patient})
	return remote.then(d => {
		db.patients.put(d)
		return d
	})
}

export function deletePatient(patient) {
	return deleteElem(PATIENTS, {elem:patient.id}).then(() => db.patients.delete(patient.id))
}

export function useGetPatient(fiscalCode) {
	return useLiveQuery(() => db.patients.get({fiscalCode}))
}

export function useGetPatientNames() {
	const [lookuptableNames, setLookupTableNames] = useState({})
	let users = useGetPatients()

	useEffect(() => {
		let table = {}
		users?.forEach(u => table[u.id] = `${u?.surname} ${u?.name}`)
		setLookupTableNames(table)
	}, [users])
	return lookuptableNames
}
