import React, {useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import clsx from "clsx";
import  PropTypes from "prop-types";
import Box from "@mui/material/Box";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import TextInput from "../forms/TextInput";
import {Button} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    buttonRow: {
        flexGrow: 2,
        marginTop: "auto",
        marginBottom: "auto",
        overflowX: "scroll",
        "-ms-overflow-style": "none",  // IE 10+
        scrollbarWidth: "none",  // Firefox
        "&::-webkit-scrollbar": {
            display: "none" // Safari and Chrome
        },
        "& button": {
            marginRight: "0.5rem"
        }
    },
    button: {
        backgroundColor: theme.palette.disabled.main,
        color: theme.palette.primary.dark,
        padding: "0.5rem 0.75rem",
        verticalAlign: "middle",
        textAlign: "center",
        fontSize: "0.875rem",
        lineHeight: "1",
        textTransform: "upperCase",
        fontWeight: 700,
        cursor: "pointer",
        marginRight: "0.3rem",
        marginBottom: "0.3rem",
        minWidth: "5rem",
        borderRadius: '10px',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: "#FFF"
        }
    },
    selected: {
        backgroundColor: theme.palette.primary.main,
        color: "#FFF"
    },
    addButton: {
        color: theme.palette.primary.dark,
        marginRight: "2rem"
    },
    addText: {
        fontWeight: 700,
    }
}));

export default function FilterAndSearchBar({
                                               addElement,
                                               allButton,
                                               allText="all",
                                               filters = [],
                                               onFilter,
                                               onSearch,
                                               selected,
                                               addText
                                           }) {

    const classes = useStyles();
    let [search, setSearch] = useState(null);

    return (
        <Grid container alignItems={"center"} sx={{marginTop:"1rem"}}>
            <Grid item>
                <Grid container>
                    {
                        allButton &&
                        <Box
                            onClick={() => {
                                onFilter && onFilter(null);
                            }}
                            className={clsx(classes.button, {[classes.selected]: selected === null})}
                        >
                            {allText}
                        </Box>
                    }
                    {
                        filters.map(({id, name}) =>
                            <Box
                                onClick={() => {
                                    onFilter && onFilter(id);
                                }}
                                className={clsx(classes.button, {[classes.selected]: selected === id})}
                                key={id}
                            >
                                {name}
                            </Box>
                        )
                    }
                </Grid>
            </Grid>
            <div style={{flexGrow: 1}}/>
            <Grid item>
                {
                    addElement && <Button sx={{marginRight: "2rem"}} color={'primary'} startIcon={<AddCircleOutlinedIcon/>} onClick={addElement}>{addText}</Button>

                    // <AddNewButton   text={addText}/>
                }
            </Grid>
            <Grid item>
                {onSearch &&
                    <TextInput
                        color={"primary"}
                        label="Cerca"
                        variant="outlined"
                        value={search}
                        onTextChange={value => {
                            setSearch(value);
                            onSearch(value);
                        }}/>
                }
            </Grid>
        </Grid>
    );
}

FilterAndSearchBar.propTypes = {
    filters: PropTypes.array.isRequired,
}
