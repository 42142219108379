import {useQuery} from "react-query";
import {
	AUTH, deleteElem,
	get, getErrorMessage, post, put,
	USERS
} from "./Client";
import {getToken, saveToken} from "./TokenManager";
import {useEffect, useState} from "react";
import {db} from "./db";
import {useLiveQuery} from "dexie-react-hooks";

export function syncronyzeUsers() {
	get(USERS).then(data => {
		db.users.clear()
		db.users.bulkAdd(data)
	})
}

export function useGetUsers() {
	return useLiveQuery(() => db.users.toArray())
}

export function useGetUserNames() {
	const [lookuptableNames, setLookupTableNames] = useState({})
	let users = useGetUsers()

	useEffect(() => {
		let table = {}
		users?.forEach(u => table[u.id] = `${u?.surname} ${u?.name}`)
		setLookupTableNames(table)
	}, [users])
	return lookuptableNames
}

export function saveUser(user) {
	let remote = user.uid ? put(`${USERS}/${user.uid}`, {body: user}) : post(`${USERS}`, {body: user})
	return remote.then(d => db.users.put(d))
}

export function deleteUser(user) {
	return deleteElem(USERS, {elem:user.id}).then(() => db.users.delete(user.id))
}

export function useGetProfileUser(uid) {
	const {status, data, error} = useQuery([USERS, {user: uid}], () => get(USERS, {elem: uid}));
	return {status, user: data || {}, error};
}

export function useGetUsersByRole(role) {
	return useLiveQuery(() => db.users.where('role').equals(role.id).toArray())
}

export function useGetUsersBySupervisor(supervisor) {
	return  useLiveQuery(() => db.users.where('supervisor').equals(supervisor || "").toArray())
}

export function useGetUserByUid(uid="") {
	return useLiveQuery(() => db.users.get(uid))
}
export async function getUserByUid(id) {
	return await db.users.get(id)
}

export function login(email, password) {
	return new Promise((resolve, reject) => {
		post(`${AUTH}`, {body: {email, password}})
			.then((data) => {
				const { user, ...token } = data;
				saveToken(token)
				resolve(data)
			})
			.catch(e => {
				let message = getErrorMessage(e)
				reject(message)
			});
	})
}

export function refreshToken() {
	return new Promise((resolve, reject) => {
		let token = getToken()
		if(token && token.refreshToken) {
			return post(`${AUTH}/refresh`, {body:{refreshToken:token.refreshToken}}).then((data) => {
				token.accessToken=data.accessToken
				saveToken(token)
				resolve(data)
			}).catch(e => reject(getErrorMessage(e)))
		} else {
			reject()
		}
	})

}
