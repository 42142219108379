import React, {useContext, useState} from "react";

import {Box, Grid, IconButton, Paper} from "@mui/material";
import {
    deleteDevice, useGetConfigurationRegistersTable,
    useGetDevice,
    useGetDeviceCommands,
    useGetDeviceHistory
} from "../services/DeviceDataManager";
import {useHistory, useParams} from "react-router-dom";
import NTMXGrid, {timestampFormatter} from "../components/NTMXGrid";

import logo from '../images/bconnect/macchina-50.svg'
import edit from "../images/bconnect/edit.svg"
import editOver from "../images/bconnect/edit-over@3x.svg"
import {useTheme} from "@mui/styles";
import {Delete, Edit, Send} from "@mui/icons-material";
import DeviceModal from "../components/modals/DeviceModal";
import ConfirmModal from "../components/modals/ConfirmModal";
import HoverableIconButton from "../components/hoverables/HoverableIconButton";
import HistoryNoteModal from "../components/modals/HistoryNoteModal";
import {UserContext} from "./App";
import {useGetUserByUid, useGetUserNames} from "../services/UserDataManager";
import {roles} from "../services/ability";
import AssignDoctorModal from "../components/modals/AssignDoctorModal";
import FilterAndSearchBar from "../components/layout/FilterAndSearchBar";
import {GridCellExpand} from "../components/GridCellExpand";
import StartIconButton from "../components/buttons/StartIconButton";
import SendCommandModal from "../components/modals/SendCommandModal";
import AssociatePatientModal from "../components/modals/AssociatePatientModal";
import {useGetPatientNames} from "../services/PatientDataManager";

const TextItem = ({label, value, editable}) => {
    const theme = useTheme()
    let color = editable ? theme.palette.primary.main : theme.palette.primary.dark

    return  <Box sx={{
        margin: theme.spacing(1),
        borderBottom: `${color} 2px solid`
    }}>
        <div style={{
            textTransform: "uppercase",
            color: theme.palette.primary.dark,
        }}>{label}</div>
        <div style={{color}}>{value || "-"}</div>
    </Box>
}

const DoctorCell = ({assignee, role, tid}) => {
    const [assignToMe, setAssignToMe] = useState(false)
    const [assign, setAssign] = useState(false)
    const user = useGetUserByUid(assignee);
    let label = user && `${user?.surname} ${user?.name}`

    let onclick
    if(role === roles.USER.id) {
        label = label || "ASSEGNA A ME"
        onclick = () => setAssignToMe(true)
    } else if(role === roles.SUPERVISOR.id) {
        onclick = () => setAssign(true)
        label = label || "ASSEGNARE"
    } else return label || " - "

    return <div>
        {label}
        <HoverableIconButton src={edit} hoverSrc={editOver} onClick={onclick}/>
        <ConfirmModal open={assignToMe} onClose={() => setAssignToMe(false)} onSave={()=>null}>
            Confermi di essere colui che ha effettuato questo trattamento?
        </ConfirmModal>
        <AssignDoctorModal onClose={() => setAssign(false)} open={assign} tid={tid}/>
    </div>
}

const RegisterCell = ({value, colDef, conf=1}) => {
    let registers = useGetConfigurationRegistersTable(conf)
    return <GridCellExpand value={value.map(v => {
        let r = registers[v.register] || {}
        let show = v.value
        if(r.type === 1) show = r.values.find(rv => rv.value===v.value)?.name
        return {showValue: `${r.name}: ${show}`}
    })} width={colDef.width}/>
}

export function DeviceHistory() {

    const {sn} = useParams();
    const {treatments} = useGetDeviceHistory(sn)
    const {commands} = useGetDeviceCommands(sn)
    const device = useGetDevice(sn)
    const history = useHistory()
    const loggedUser = useContext(UserContext);
    const [filter, setFilter] = useState("treats")
    const [openModal, setOpenModal] = useState(false)
    const [editModal, setEditModal] = useState()
    const [sendCommand, setSendCommand] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [assignPatient, setAssignPatient] = useState()
    let lookuptableNames = useGetUserNames()
    let lookuptablePatients = useGetPatientNames()

    const onDeleteDevice = () => {
        deleteDevice(device).then(() => history.replace("/"))
    }

    let data
    let lastCommand = 0
    if(filter === "treats") {
        data = treatments.slice();
        data.sort((a, b) => b.startTimestamp - a.startTimestamp);
    } else {
        data = commands.slice()
        data.sort((a, b) => b.timestamp - a.timestamp);
        lastCommand = data[0]?.timestamp
    }
    return <Paper style={{padding: "2rem"}}>

        <Grid container direction={"row"}>
            <Grid item container direction={"column"} xs={1}>
                <Grid item><img src={logo} alt={'logo'}/></Grid>
            </Grid>
            <Grid item container direction={"column"} xs>
                <Grid item>
                    <TextItem label="Seriale" value={device?.serialNumber}/>
                </Grid>
                <Grid item>
                    <TextItem label="ID Interno" value={device?.alias}/>
                </Grid>
            </Grid>
            <Grid item container direction={"column"} xs>
                <TextItem label="nome macchina" value={device?.name} editable/>
                <TextItem label="Responsabile" value={lookuptableNames[device?.supervisor]} editable/>
            </Grid>
            <Grid item container direction={"column"} xs>
                <TextItem label="Operatori assegnati" value={device?.doctors?.map(u => <li key={u}>{lookuptableNames[u]}</li>)} editable/>
            </Grid>
            <Grid item>
                <IconButton onClick={() => setOpenModal(true)}><Edit color={"primary"}/></IconButton>
                <IconButton onClick={() => setConfirm(true)}><Delete color={"primary"}/></IconButton>
            </Grid>
        </Grid>
        <FilterAndSearchBar filters={[{id:"treats", name:"Trattamenti"},{id:"comm", name:"Comandi"}]}
                            onFilter={setFilter}
                            selected={filter}/>
        {filter === "treats" && <NTMXGrid
            columns={[
                {
                    field: "startTimestamp",
                    headerName: "Inizio",
                    flex: 1,
                    ...timestampFormatter
                },
                {
                    field: "endTimestamp",
                    headerName: "Fine",
                    flex: 1,
                    ...timestampFormatter
                },
                {
                    field: "name",
                    headerName: "Trattamento",
                    flex: 1,
                },
                {
                    field: "doctor",
                    headerName: "Operatore",
                    flex: 1,
                    valueFormatter: ({value}) => lookuptableNames[value],
                    renderCell: ({value, row}) => <DoctorCell assignee={value} role={loggedUser.data.role}
                                                              tid={row.id}/>
                },
                {
                    field: "patient",
                    headerName: "Cliente",
                    flex: 1,
                    valueFormatter: ({value}) => lookuptablePatients[value],
                    renderCell: ({value, row}) => <div>{lookuptablePatients[value]}  <HoverableIconButton src={edit} hoverSrc={editOver}
                                                                                     onClick={() => setAssignPatient(row)}/></div>
                },
                {
                    field: "details",
                    headerName: "Note",
                    flex: 2,
                    renderCell: ({value, row}) => <div>{value} {loggedUser.data.role !== roles.ADMIN.id && <HoverableIconButton src={edit} hoverSrc={editOver}
                                                                                     onClick={() => setEditModal(row)}/>}
                    </div>
                }
            ]}
            rows={data || []}
            title="Registro trattamenti"
            getRowId={(row) => data && row.id}
        />}
        {filter === "comm" && <NTMXGrid
            columns={[
                {
                    field: "timestamp",
                    headerName: "Ora invio",
                    flex: 1,
                    ...timestampFormatter
                },
                {
                    field: "ackTimestamp",
                    headerName: "Ora ricezione",
                    flex: 1,
                    ...timestampFormatter
                },
                {
                    field: "user",
                    headerName: "Utente",
                    flex: 1,
                    valueFormatter: ({value}) => lookuptableNames[value],
                },
                {
                    field: "registers",
                    headerName: "Valori",
                    flex: 2,
                    renderCell: props => <RegisterCell conf={device.configuration} {...props}/>
                }
            ]}
            rows={data || []}
            title="Registro comandi"
            getRowId={(row) => treatments && row.id}
            rightButton={<Grid container justifyContent={"flex-end"}>
                {<StartIconButton
                    onClick={() => setSendCommand(true)}
                    title={"Invia comando"}
                    startIcon={<Send/>}
                />
                }
            </Grid>}
        />}
        <DeviceModal open={openModal} onClose={() => setOpenModal(false)} device={device}/>
        <ConfirmModal open={confirm} onClose={() => setConfirm(false)} onSave={onDeleteDevice}>
            Confermi di voler eliminare questa Macchina? Verranno eliminati tutti i dati ad essa associati.
            Ti consigliamo di scaricarne i dati dalla schermata dettaglio prima.
        </ConfirmModal>
        <HistoryNoteModal open={!!editModal} treatment={editModal} onClose={() => setEditModal(undefined)} />
        <AssociatePatientModal open={assignPatient} onClose={() => setAssignPatient(null)} treatment={assignPatient}/>
        {device &&
            <SendCommandModal open={!!sendCommand} onClose={() => setSendCommand(false)}
                           configuration={device?.configuration} sn={sn} lastCommand={lastCommand}/>}
    </Paper>

}
