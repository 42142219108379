import React, {useContext, useEffect, useState} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {LoginPage} from "../components/pages/LoginPage";
import ResetPassword from "../components/pages/ResetPassword";
import ConfirmEmail from "../components/pages/ConfirmEmail";
import Main from "./Main";
import {login, refreshToken} from "../services/UserDataManager";
import {deleteToken, saveToken} from "../services/TokenManager";
import ChangePasswordPage from "../components/pages/ChangePasswordPage";
import ForgotPasswordPage from "../components/pages/ForgotPasswordPage";
import ability from "../services/ability";
import {AbilityContext} from "../services/Can";
import {db} from "../services/db";

const defaultContext = {status:{ready:false}, data:{}, onLogout: () => undefined}

export const UserContext = React.createContext(defaultContext);

function App() {
    let [user, setUser] = useState({status:{ready:false}, data:{}, onLogout: logout})
    let [error, setError] = useState(null)

    useEffect(() => {
        onLogin()
        window.addEventListener("focus", onLogin);
        return () => window.removeEventListener("focus", onLogin);
    }, []);

    function logout() {
        deleteToken()
        db.delete()
        db.open()
        setUser({
            ...user,
            data: {},
            status: {isLogged: false, ready: true}
        })
    }

    function onLogin(email, password) {
        let promise
        if(email && password) promise = login(email, password)
        else promise = refreshToken()
        promise.then(resp => {
            setUser({
                ...user,
                status: {
                    isLogged: true,
                    ready: true
                },
                data:resp.user
            })
        }).catch(e => {
            setError(e)
            setUser({
                ...user,
                status: {
                    isLogged: false,
                    ready: true,
                    changePassword: e === "New password is required" ? email : undefined
                }
            })
        })
    }

    function onResetPassword(username) {

    }

    function onSuccessUpdatePassword(data) {
        const {accessToken, expiresIn, refreshToken} = data
        saveToken({accessToken,expiresIn,refreshToken})
        setUser({
            ...user,
            status: {
                isLogged: true,
                ready: true
            },
            data: data.user
        })
    }

    if (!user.status.ready) return <div/>

    if (user.status.changePassword) return <ChangePasswordPage onSuccess={onSuccessUpdatePassword} email={user.status.changePassword}/>;

    return (
        <UserContext.Provider value={user}>
            <Switch>
                <UnloggedRoute path="/login" setError={setError} redirect>
                    <LoginPage onSubmit={onLogin} onResetPassword={onResetPassword}
                               error={error}/>
                </UnloggedRoute>
                <UnloggedRoute path="/resetPassword">
                    <ResetPassword username={user.data.username || "guest"}/>
                </UnloggedRoute>
                <UnloggedRoute path="/confirmEmail" setError={setError} redirect>
                    <ConfirmEmail/>
                </UnloggedRoute>
                <UnloggedRoute path="/forgotPassword" setError={setError} redirect>
                    <ForgotPasswordPage/>
                </UnloggedRoute>
                <PrivateRoute path="/">
                    <AbilityContext.Provider value={ability(user.data)}>
                        <Main onLogout={logout}/>
                    </AbilityContext.Provider>
                </PrivateRoute>
            </Switch>
        </UserContext.Provider>
    );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({children, ...rest}) {
    let user = useContext(UserContext) || {};

    return (
        <Route
            {...rest}
            render={({location}) =>
                user.status.isLogged ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
}

function UnloggedRoute({redirect, children, setError, ...rest}) {
    const user = useContext(UserContext) || {};

    return (
        <Route
            {...rest}
            render={() =>
                user.status.isLogged && redirect ? (
                    <Redirect
                        to={{
                            pathname: "/"
                        }}
                    />
                ) : (
                    children
                )
            }
        />
    );
}

export default App;
