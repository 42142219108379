import React, {useEffect, useState} from "react";
import {TextInputContainer} from "../forms/TextInput";
import BaseModal from "./BaseModal";
import { saveDeviceConfiguration} from "../../services/DeviceDataManager";
import {getErrorMessage} from "../../services/Client";
import {useSnackbar} from "notistack";

const form = [
    {label:"Nome", field: "name"},
    {label:"Registro del trattamento", field: "treatmentRegister", type:"number"},
]

export default function DeviceModelModal({open, onClose, device}){

    let [values, setValues] = useState(device || {});
    let [error, setError] = useState({});
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        if(device) setValues(device)
    }, [device])

    const close = () => {
        setValues({});
        setError({});
        onClose();
    }

    const save = () => {
        setError({})
        saveDeviceConfiguration(values)
            .then(() => {
                enqueueSnackbar("Salvato", {variant: "success"})
                close()
            })
            .catch(e => setError({general:getErrorMessage(e)}))
    }

    const onTextChange = (value, name) => {
        let newValues = {...values};
        setError({});
        newValues[name] = value;
        setValues(newValues);
    }

    return <BaseModal open={open} onClose={close} onSave={save} error={error.general}>
            {
                form.map(({field, label, type="string", readOnly=false}) =>
                    <TextInputContainer
                        xs={12} label={label} key={field} value={values[field]} type={type} error={error[field]}
                        disabled={readOnly && values.id} onTextChange={(value) => {onTextChange(value, field)}}
                    />)
            }
    </BaseModal>
}
