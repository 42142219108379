
import {TextInputContainer} from "../forms/TextInput";
import BaseModal from "./BaseModal";
import React, {useContext, useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {saveUser, useGetUsersByRole} from "../../services/UserDataManager";
import {getErrorMessage} from "../../services/Client";
import {CheckBoxContainer} from "../forms/CheckBoxContainer";
import {Grid} from "@mui/material";
import {getRoleList, roles} from "../../services/ability";
import {UserContext} from "../../containers/App";
import NTMSelect from "../NTMSelect";

const form = [
    {label:"Nome", field: "name"},
    {label:"Cognome", field: "surname"},
    {label:"E-Mail", field: "email"},
]

export default function UserModal({open, onClose, user}){

    let [values, setValues] = useState(user || {})
    let [error, setError] = useState({})
    const loggedUser = useContext(UserContext);
    const supervisors = useGetUsersByRole(roles.SUPERVISOR) || []
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        if(user) setValues(user)
    }, [user])

    const close = () => {
        setValues({});
        setError({});
        onClose();
    }

    const save = () => {
        setError({})
        if(!values.email) {
            setError({email : "Campo obbligatorio"});
            return;
        }
        saveUser(values)
            .then(() => {
                enqueueSnackbar("Salvato", {variant: "success"})
                close()
            })
            .catch(e => setError({general:getErrorMessage(e)}))
    }

    const onTextChange = (value, name) => {
        let newValues = {...values};
        setError({});
        newValues[name] = value;
        setValues(newValues);
    }

    const formatName = u => u ? `${u.surname} ${u.name}` : null

    return <BaseModal open={open} onClose={close} onSave={save} error={error.general}>
            {
                form.map(({field, label, type="string", readOnly=false}) =>
                    <TextInputContainer
                        xs={6} label={label} key={field} value={values[field]} type={type} error={error[field]}
                        disabled={readOnly && values.id} onTextChange={(value) => {onTextChange(value, field)}}
                    />)
            }
            <Grid item xs={6} style={{padding: "16px"}}>
            <CheckBoxContainer value={values.role} onChange={(value) => {onTextChange(value, "role")}}
                               fields={getRoleList(loggedUser.data).map(u => ({...u, label:u.name}))}/>
            </Grid>
        {values.role === roles.USER.id && <Grid item sm={6} style={{padding: "16px"}}>
            <NTMSelect
                label={"Responsabile"}
                items={supervisors.map(d => ({value: d.id, text: formatName(d)}))}
                onChange={value => setValues({
                    ...values,
                    supervisor:value})}
                value={values.supervisor || ""}
            />
        </Grid>}
    </BaseModal>
}
