import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";
import {SnackbarProvider} from "notistack";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import {Button, createTheme} from "@mui/material";
import {itIT} from "@mui/material/locale";
import { StyledEngineProvider } from '@mui/material/styles';
import {ThemeProvider} from "@mui/styles";
import {Close} from "@mui/icons-material";
import {LicenseInfo} from "@mui/x-data-grid-pro";

import App from './containers/App';
import {LocalizationProvider} from "@mui/x-date-pickers-pro";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs from "dayjs";
require('dayjs/locale/it')

const theme = createTheme({
    palette: {
        primary: {
            main: "#0478BE",
            light: "#fcd4d4",
            dark: "#344248", //"#32323a",
            contrastText: '#fff',
        },
        secondary: {
            main: "#B99700", //"#e14d43",
            light: "#e7e7ef",
        },
        text: {
            primary: "#290e08",
            light:'#fff'
        },
        error: {
            main: "#ec1807",
            light: "#ffc900"
        },
        green:{
            main: "#8BC34A",
            light: "#DCEDC8"
        },
        disabled: {
            main: "#EEE"
        }
    },
    typography: {
        body1: {
            fontSize: "0.875rem",
            lineHeight: 1.43,
            letterSpacing: "0.01071em",
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    whiteSpace: "nowrap",
                    verticalAlign: "middle",
                    userSelect: "none",
                    border: "1px solid transparent",
                    padding: "0.75rem 1rem",
                    fontWeight: "bold",
                    textAlign: "center",
                    borderRadius: 0,
                    minWidth: "auto",
                    fontSize: "0.875rem",
                    lineHeight: "1.25",
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root : {
                    "&:hover" : {backgroundColor : '#D4EDFC'}
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontSize: '0.875rem',
                    lineHeight: 1.43,
                    letterSpacing: '0.01071em',
                },
            },
        }
    }
}, itIT);

const notistackRef = React.createRef();
const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
};

Bugsnag.start({
    apiKey: '40dd307e17b53cb8fca166ff3e599454',
    plugins: [new BugsnagPluginReact()]
})
const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React)

LicenseInfo.setLicenseKey(
    '96982ca227141c27dcacdc03f96229ecT1JERVI6NDA4ODQsRVhQSVJZPTE2ODAzNjY1NjMwMDAsS0VZVkVSU0lPTj0x',
);

const queryClient = new QueryClient()

var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
dayjs.locale('it')


ReactDOM.render(
  <React.StrictMode>
      <ErrorBoundary>
          <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                  <LocalizationProvider localeText={'it'} dateAdapter={AdapterDayjs} dateFormats={{keyboardDate:"DD/MM/YYYY"}} adapterLocale={"it"}>
                      <SnackbarProvider
                          ref={notistackRef}
                          action={(key) => (
                              <Button onClick={onClickDismiss(key)}>
                                  {<Close/>}
                              </Button>
                          )}
                          autoHideDuration={3000}>
                              <QueryClientProvider client={queryClient}>
                                  <Router>
                                      <App/>
                                  </Router>
                              </QueryClientProvider>
                      </SnackbarProvider>
                  </LocalizationProvider>
              </ThemeProvider>
          </StyledEngineProvider>
      </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
