import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {Grid, Typography} from "@mui/material";

const useStyles = makeStyles((theme) => ({
	root: {
		color:theme.palette.primary.contrastText,
		"&::-webkit-scrollbar": {
			width: 5,
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: theme.palette.primary.contrastText,
			borderRadius: "10px"
		},
	},
}));

export default function BaseModal({open, onClose, onSave, children, error, iconButton, onClickButton, fullWidth}) {

	const classes = useStyles();

	return (
        <Dialog open={open} onClose={onClose} fullWidth={fullWidth} maxWidth={fullWidth && "lg"}
				sx={{
					"& .MuiPaper-root": {
						backgroundColor: "primary.dark",
						borderRadius:5,
						maxHeight: "80vh"
					}
				}}>
			<DialogTitle>
				<Grid container justifyContent={"space-between"}>
					<Grid item>
						{
							iconButton &&
							<IconButton onClick={onClickButton} size="large">
								{iconButton}
							</IconButton>
						}
					</Grid>
					<Grid item>
						{
							onSave &&
							<IconButton onClick={onSave} size="large">
								<CheckIcon/>
							</IconButton>
						}
						<IconButton onClick={onClose} size="large">
							<CloseIcon/>
						</IconButton>
					</Grid>

			</Grid>
			</DialogTitle>
			<DialogContent className={classes.root}>{error && <Typography align={"center"}>{error}</Typography>}
				<Grid container spacing={4} style={{margin: 0, width: "100%", marginBottom: "1.5rem"}}>
					{children}
				</Grid>
			</DialogContent>
		</Dialog>
    );
}
