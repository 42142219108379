
import TextInput, {TextInputContainer} from "../forms/TextInput";
import BaseModal from "./BaseModal";
import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {getErrorMessage, TREATMENTS} from "../../services/Client";
import {Box, Grid} from "@mui/material";
import {getPatient, savePatient, useGetPatient, useGetPatients} from "../../services/PatientDataManager";
import {useTheme} from "@mui/styles";
import {updateDeviceHistory} from "../../services/DeviceDataManager";
import {useQueryClient} from "react-query";

const form = [
    {label:"Nome", field: "name"},
    {label:"Cognome", field: "surname"},
    {label:"Codice Fiscale", field: "fiscalCode", readOnly:true},
    {label:"Data di nascita", field: "birthDate"}
]

const TextItem = ({label, value}) => {
    const theme = useTheme()
    let color = theme.palette.secondary.light

    return  <Box sx={{
        margin: theme.spacing(1),
        borderBottom: `${color} 2px solid`
    }}>
        <div style={{
            textTransform: "uppercase",
            color: theme.palette.secondary.light,
        }}>{label}</div>
        <div style={{color}}>{value || "-"}</div>
    </Box>
}

export default function AssociatePatientModal({open, onClose, treatment}){

    let [values, setValues] = useState({})
    let [error, setError] = useState({})
    let [step, setStep] =useState(1)
    let patients = useGetPatients()
    const {enqueueSnackbar} = useSnackbar()
    const queryClient = useQueryClient()

    const close = () => {
        setValues({});
        setError({});
        setStep(1)
        onClose();
    }

    const save = () => {
        if(step===1) {
            let p = patients.find(p => p.fiscalCode===values.fiscalCode)
            if(p) setValues(p)
            setStep(p ? 2 : 3)
        } else if(step===2) {
            updateDeviceHistory(treatment.id, {patient:patients.find(p => p.fiscalCode===values.fiscalCode).id})
                .then(() => {
                    enqueueSnackbar("Salvato", {variant: "success"})
                    queryClient.invalidateQueries([TREATMENTS, {device: treatment.machine}])
                    close()
                })
                .catch(e => setError({general:getErrorMessage(e)}))
        } else {
            setError({})
            savePatient(values)
                .then(p => {
                    updateDeviceHistory(treatment.id, {patient:p.id})
                        .then(() => {
                            enqueueSnackbar("Salvato", {variant: "success"})
                            queryClient.invalidateQueries([TREATMENTS, {device: treatment.machine}])
                            close()
                        })
                        .catch(e => setError({general:getErrorMessage(e)}))
                })
                .catch(e => setError({general:getErrorMessage(e)}))
        }
    }

    const onTextChange = (value, name) => {
        let newValues = {...values};
        setError({});
        newValues[name] = value;
        setValues(newValues);
    }

    return <BaseModal open={open} onClose={close} onSave={save} error={error.general}>
        {
            step === 1 &&
            <TextInputContainer
                xs={12} label={"Codice Fiscale"} value={values.fiscalCode} type={"string"} error={error.fiscalCode}
                onTextChange={(value) => {onTextChange(value, "fiscalCode")}}
            />
        }
        {
            step===2 &&
            <>
                {
                    form.map(({field, label}) =>  <Grid key={field} item xs={6} style={{padding: "16px"}}><TextItem label={label} value={values[field]}/></Grid>)
                }
                <Grid item xs={12} style={{padding: "16px"}}><TextItem label={"Anamnesi"} value={values.anamnesis}/></Grid>
            </>

        }
        {
            step === 3 &&
                <>
                    {
                        form.map(({field, label, type = "string", readOnly = false}) =>
                            <TextInputContainer
                                xs={6} label={label} key={field} value={values[field]} type={type} error={error[field]}
                                disabled={readOnly } onTextChange={(value) => {
                                onTextChange(value, field);
                            }}
                            />)
                    }
                    <Grid item xs={12} style={{padding: "16px"}}>
                        <TextInput
                            multiline rows={5} label={"Anamnesi"} value={values.anamnesis} error={error.anamnesis}
                            onTextChange={(value) => {onTextChange(value)}}
                        />
                    </Grid>
                </>

        }
    </BaseModal>
}
