
import BaseModal from "./BaseModal";
import React, {useState} from "react";
import {useSnackbar} from "notistack";
import {useGetUsers} from "../../services/UserDataManager";
import {getErrorMessage, TREATMENTS} from "../../services/Client";
import {Grid} from "@mui/material";
import NTMSelect from "../NTMSelect";
import {updateDeviceHistory} from "../../services/DeviceDataManager";
import {useQueryClient} from "react-query";

export default function AssignDoctorModal({open, onClose, tid}){

    let [doctor, setDoctor] = useState()
    let [error, setError] = useState({})
    const supervisors = useGetUsers() || []
    const {enqueueSnackbar} = useSnackbar()
    let queryClient = useQueryClient();

    const close = () => {
        setDoctor(null);
        setError({});
        onClose();
    }

    const save = () => {
        setError({})
        if(!doctor) {
            setError({doctor : "Specificare Operatore"});
            return;
        }
        updateDeviceHistory(tid, {doctor})
            .then(() => {
                enqueueSnackbar("Salvato", {variant: "success"})
                close()
            })
            .catch(e => setError({general:getErrorMessage(e)}))
            .finally(() => queryClient.invalidateQueries([TREATMENTS]));
    }

    const formatName = u => u ? `${u.surname} ${u.name}` : null

    return <BaseModal open={open} onClose={close} onSave={save} error={error.general}>

        <Grid item style={{padding: "16px", minHeight: "15rem"}}>
            <NTMSelect
                label={"Operatore"}
                items={supervisors.map(d => ({value: d.id, text: formatName(d)}))}
                onChange={setDoctor}
                value={doctor || ""}
            />
        </Grid>
    </BaseModal>
}
