
import TextInput, {TextInputContainer} from "../forms/TextInput";
import BaseModal from "./BaseModal";
import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {getErrorMessage} from "../../services/Client";
import {Grid} from "@mui/material";
import {savePatient} from "../../services/PatientDataManager";
import {DatePicker} from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";

const form = [
    {label:"Nome", field: "name"},
    {label:"Cognome", field: "surname"},
    {label:"Codice Fiscale", field: "fiscalCode"}
]

export default function PatientModal({open, onClose, patient}){

    let [values, setValues] = useState(patient || {})
    let [error, setError] = useState({})
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
                if(patient) setValues(patient)
    }, [patient])

    const close = () => {
        setValues({});
        setError({});
        onClose();
    }

    const save = () => {
                setError({})
        savePatient({...values})
            .then(() => {
                enqueueSnackbar("Salvato", {variant: "success"})
                close()
            })
            .catch(e => setError({general:getErrorMessage(e)}))
    }

    const onTextChange = (value, name) => {
        let newValues = {...values};
        setError({});
        newValues[name] = value;
        setValues(newValues);
    }

    return <BaseModal open={open} onClose={close} onSave={save} error={error.general}>
            {
                form.map(({field, label, type="string", readOnly=false}) =>
                    <TextInputContainer
                        xs={6} label={label} key={field} value={values[field]} type={type} error={error[field]}
                        disabled={readOnly && values.id} onTextChange={(value) => {onTextChange(value, field)}}
                    />)
            }
        <Grid item xs={6} style={{padding: "16px"}}>
        <DatePicker
            label="Data di nascita"
            value={values.birthDate ? values.birthDate : null}
            onChange={newValue => onTextChange(newValue.format('YYYY-MM-DD'), "birthDate")}
            renderInput={params => <TextInput {...params} value={params.inputProps.value}/>}
            maxDate={dayjs()}
        />
        </Grid>
        <Grid item xs={12} style={{padding: "16px"}}>
                <TextInput
                    multiline rows={5} label={"Anamnesi"} value={values.anamnesis} error={error.anamnesis}
                    onTextChange={(value) => {onTextChange(value, "anamnesis")}}
                />
            </Grid>
    </BaseModal>
}
