
import React, {useState} from "react";

import {
    Button,
    Card,
    CardActionArea,
    CardContent,
    Typography, Grid
} from "@mui/material";
import {useGetConfigurations, useGetDevices} from "../services/DeviceDataManager";
import {useHistory} from "react-router-dom";
import DeviceModal from "../components/modals/DeviceModal";
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/AddCircle';
import TextInput from "../components/forms/TextInput";
import InputAdornment from "@mui/material/InputAdornment";
import Container from "@mui/material/Container";
import deviceIcon from "../images/bconnect/macchina-33.svg";
import deviceIconOver from "../images/bconnect/macchina-33-over.svg";
import {useTheme} from "@mui/material/styles";
import Can from "../services/Can";
import {abilities, resources} from "../services/ability";
import FilterAndSearchBar from "../components/layout/FilterAndSearchBar";

let DeviceItem = ({id, name, serialNumber, configuration}) => {
    let [hover, setHover] = useState(false)
    const history = useHistory()
    const theme = useTheme();

    return <Grid key={id} sm={6} item> <Card
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        sx={{
            backgroundColor:'primary.main',
            borderRadius: 0,
            color:'primary.contrastText',
            '&:hover': {
                bgcolor: theme.palette.secondary.main
            }
        }}>
        <CardActionArea onClick={() => history.push(`devices/${serialNumber}`)}>
            <CardContent>
                <Grid container direction={"row"} spacing={2} alignItems={"center"}>
                    <Grid item sm={2}>
                        <img src={hover ? deviceIconOver : deviceIcon} alt={'device icon'}/>
                    </Grid>
                    <Grid sm={10} item container direction={"row"} spacing={2} justifyContent={"space-between"}>
                        {name &&
                            <Grid item>
                                <Typography variant="body2" color="text.light">
                                    {name}
                                </Typography>
                                <Typography variant="body2" color="text.light" fontStyle={"italic"}>
                                    {configuration}
                                </Typography>
                            </Grid>
                        }
                        <Grid item justifyItems={"flex-end"}>
                            <Typography variant="body2" color="text.light">
                                {serialNumber}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </CardActionArea>
    </Card></Grid>
}

export function DeviceManagement() {

    const devices = useGetDevices()
    const configurations = useGetConfigurations()
    const [createModal, setCreateModal] = useState(false)
    const [filter, setFilter] = useState(null)
    const [search, setSearch] = useState("")

    const getConfigurationName = id => id ? configurations?.find(c => c.id === id)?.name : "-"

    let data;
    if(filter) {
        data = devices?.filter(d => d.configuration===filter)
    } else {
        data = devices?.slice() || []
    }

    if(search) data = data.filter(d => d.name?.includes(search) || d.serialNumber.includes(search))

    return <Container>
        <Grid container direction={"row"} spacing={2} alignItems={'center'}>
            <Grid item sm={4}/>
            <Grid item sm={4}>
                <Can I={abilities.WRITE} a={resources.DEVICES}>
                    <Button color={"primary"} startIcon={<AddIcon />} onClick={() => setCreateModal(true)}>{"Aggiungi nuova macchina"}</Button>
                </Can>
            </Grid>
            <Grid item sm={4}/>
        </Grid>
        <Grid>
            {configurations && <FilterAndSearchBar
                allButton
                allText={"Tutti"}
                filters={configurations}
                selected={filter}
                onFilter={setFilter}
                onSearch={setSearch}
            />}
        </Grid>

        <Grid container item spacing={2} sx={{marginTop:"1rem"}}>
            {
                data.map(d => <DeviceItem key={d.id} id={d.id} name={d.name} serialNumber={d.serialNumber} configuration={getConfigurationName(d.configuration)}/>)
            }
        </Grid>
        {createModal && <DeviceModal open={createModal} onClose={() => setCreateModal(false)}/>}
    </Container>

}
