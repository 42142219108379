import menuItems from "../constants/_nav";
import {abilities, resources} from "./ability";

let setMenu = (ability) => {
    let items = [menuItems.dashboard];

    if(ability.can(abilities.READ, resources.PATIENTS)) items.push(menuItems.patients);
    items.push(menuItems.users);
    if(ability.can(abilities.READ, resources.CONFIGURATIONS)) items.push(menuItems.configuration)
    return items;
}

export {setMenu};
