import Grid from "@mui/material/Grid";
import TextInput, {TextInputContainer} from "../forms/TextInput";
import BaseModal from "./BaseModal";
import React, {useEffect, useState} from "react";
import {useGetUsersByRole} from "../../services/UserDataManager";
import {saveDevice, useGetConfigurations} from "../../services/DeviceDataManager";
import {getErrorMessage} from "../../services/Client";
import {useSnackbar} from "notistack";
import {roles} from "../../services/ability";
import NTMSelect from "../NTMSelect";

const form = [
    {label:"Seriale", field: "serialNumber", readOnly:true},
    {label:"ID Interno", field: "alias", readOnly:true},
    {label:"Nome", field: "name"},
]

export default function DeviceModal({open, onClose, device}){

    let [values, setValues] = useState(device || {});
    let [error, setError] = useState({});
    const {enqueueSnackbar} = useSnackbar()
    const supervisors = useGetUsersByRole(roles.SUPERVISOR) || []
    const meds = useGetUsersByRole(roles.USER) || []
    const configurations = useGetConfigurations() || []

    useEffect(() => {
        if(device) setValues(device)
    }, [device])

    const close = () => {
        setValues({});
        setError({});
        onClose();
    }

    const save = () => {
        setError({})
        if(!values.serialNumber) {
            setError({serialNumber : "Campo obbligatorio"});
            return;
        }

        if(values.supervisor) values.doctors = values.doctors ? values.doctors.filter(d => d !== device.supervisor) : []
        values.doctors.unshift(values.supervisor)
        saveDevice(values)
            .then(() => {
                enqueueSnackbar("Salvato", {variant: "success"})
                close()
            })
            .catch(e => setError({general:getErrorMessage(e)}))
    }

    const onTextChange = (value, name) => {
        let newValues = {...values};
        setError({});
        newValues[name] = value;
        setValues(newValues);
    }

    const formatName = u => u ? `${u.surname} ${u.name}` : null

    return <BaseModal open={open} onClose={close} onSave={save} error={error.general}>

            {
                form.map(({field, label, type="string", readOnly=false}) =>
                    <TextInputContainer
                        xs={6} label={label} key={field} value={values[field]} type={type} error={error[field]}
                        disabled={readOnly && values.id} onTextChange={(value) => {onTextChange(value, field)}}
                    />)
            }
            <Grid item sm={6} style={{padding: "16px"}}>
                <NTMSelect
                    label={"Modello"}
                    items={configurations.map(d => ({value: d.id, text: d.name}))}
                    onChange={value =>
                        setValues({
                            ...values,
                            configuration:value
                        })
                    }
                    value={values.configuration || ""}
                />
            </Grid>
            <Grid item sm={6} style={{padding: "16px"}}>
                <NTMSelect
                    label={"Responsabile"}
                    items={supervisors.map(d => ({value: d.id, text: formatName(d)}))}
                    onChange={value =>
                        setValues({
                            ...values,
                            supervisor:value
                        })
                    }
                    value={values.supervisor || ""}
                />
            </Grid>
            <Grid item sm={6} style={{padding: "16px"}}>
                <NTMSelect
                    label={"Medici"}
                    items={meds.map(d => ({value: d.id, text: formatName(d)}))}
                    onChange={value => {
                        let val = values.doctors || []
                        val.push(value);
                        let newValues = {
                            ...values,
                            doctors: val
                        };
                        setValues(newValues);
                    }}
                    onDelete={value => {
                        let val = values.doctors
                        let newValues = {
                            ...values,
                            doctors: val.filter(c => c !== value)
                        }
                        setValues(newValues);
                    }}
                    value={values.doctors || []}
                />
            </Grid>
    </BaseModal>
}
