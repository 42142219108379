//Lista delle voci del menu
//Aggiungi un elemento nell'array per avere una nuova voce

import {Dashboard, People, Hardware} from "@mui/icons-material";
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';

const items = {
	dashboard: {
		name: "Dispositivi",
		url: "/devices",
		icon: <Dashboard/>
	},
	patients: {
		name: "Pazienti",
		url: "/patients",
		icon: <DirectionsWalkIcon/>
	},
	users: {
		name: "Utenti",
		url: "/users",
		icon: <People/>
	},
	configuration: {
		name: "Modelli",
		url: "/configurations",
		icon: <Hardware/>
	}
};
export default items
