import React, {useContext, useState} from "react";

import makeStyles from '@mui/styles/makeStyles';
import {Box, Button, Grid, IconButton, InputBase, Paper} from "@mui/material";
import {useSnackbar} from "notistack";
import {getErrorMessage, put, USERS} from "../services/Client";
import {useQueryClient} from "react-query";
import ChangeCredientalsModal from "../components/modals/ChangeCredentialsModal";
import {useParams} from "react-router-dom";
import {UserContext} from "./App";
import {Check, Close, Edit, Lock} from "@mui/icons-material";
import {useGetProfileUser} from "../services/UserDataManager";

const useStyles = makeStyles(theme => ({
    page: {
        padding: theme.spacing(2)
    },
    container: {
        marginBottom: theme.spacing(2)
    },
    box: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        borderBottom: `${theme.palette.secondary.main} 1px solid`,
        color: theme.palette.primary.dark,
        fontWeight: "bold",
        flexGrow: 1,
    },
    textField: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        border: `${theme.palette.secondary.main} 1px solid`,
        color: theme.palette.primary.dark,
        fontWeight: "bold",
        flexGrow: 1
    },
    userImage: {
        height: "8rem",
        width: "8rem",
        color: theme.palette.primary.main
    },
    editUserImage: {
        color: "#fff",
        backgroundColor: theme.palette.primary.dark,
    },
    filterBar: {
        padding: theme.spacing(4),
        paddingBottom: 0
    },
}));

const form = [
        {
            name: "name",
            label: "Nome"
        },
        {
            name: "surname",
            label: "Cognome"
        },
        {
            name: "email",
            label: "E-Mail",
            notEditable: true
        }
    ]

export default function UserProfile() {

    let classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    let queryClient = useQueryClient();
    const {uid} = useParams();
    const loggedUser = useContext(UserContext);
    let {user = {}} = useGetProfileUser(uid);
    let [isEditable, setIsEditable] = useState(false);
    let [isEditingCredentials, setIsEditingCredentials] = useState(false);
    let [editUser, setEditUser] = useState({});

    const isUserLogged = uid === loggedUser.uid;

    const onChange = (prop) => (event) => {
        setEditUser({ ...editUser, [prop]: event.target.value });
    }

    const saveEdit = () => {
        enqueueSnackbar("Saving...", {variant: "info"});
        put(USERS, {body: editUser, elem: editUser.uid})
            .then(() => {
                enqueueSnackbar("Saved", {variant: "success"});
                setIsEditable(false);
            })
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries([USERS, {user: user.uid}]));
    }

    const edit = () => {
        setEditUser({...user});
        setIsEditable(true);
    }

    return (
        <Paper className={classes.page}>
            <Grid container className={classes.container}>
                <Grid item xs={6} style={{display: "flex", justifyContent: "flex-start"}} >
                    {isUserLogged &&
                         <Button
                            color="primary"
                            startIcon={<Lock/>}
                            onClick={() => setIsEditingCredentials(true)}
                        >
                            Cambia Password
                        </Button>
                    }
                </Grid>
                <Grid item xs={6} style={{display: "flex", justifyContent: "flex-end"}} >
                    {isUserLogged && (isEditable ?
                        <Grid>
                            <IconButton onClick={saveEdit} size="large">
                                <Check/>
                            </IconButton>
                            <IconButton onClick={() => setIsEditable(false)} size="large">
                                <Close/>
                            </IconButton>
                        </Grid>
                        :
                        <IconButton onClick={edit} size="large">
                            <Edit/>
                        </IconButton>)
                    }
                </Grid>

            </Grid>

            <Grid container alignItems={"flex-end"} className={classes.container}>
                {
                    form.map(f => (!isEditable || !f.notEditable) &&
                        <Grid item xs={12} md={6} style={{display: "flex"}} key={f.name}>
                            {isEditable ?
                                <InputBase
                                    className={classes.textField}
                                    value={editUser[f.name]}
                                    onChange={onChange(f.name)}
                                    placeholder={f.label}
                                    type={f.type}/>

                                    :

                                <Box xs={6} className={classes.box}>
                                    <div style={{fontWeight: "normal"}}>{f.label}</div>
                                    <div style={{marginLeft: "1rem"}}>
                                        {user[f.name] || "-"}
                                    </div>
                                </Box>
                            }
                        </Grid>)
                }
            </Grid>

            <ChangeCredientalsModal open={isEditingCredentials} onClose={() => setIsEditingCredentials(false)}/>
        </Paper>
    );
}
