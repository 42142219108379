import MenuItem from "@mui/material/MenuItem";
import TextInput from "./TextInput";
import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

export default function SelectInputBoolean({value, inputLabel, onChange}) {

    const options = [
        {
            id: "true",
            name: <FiberManualRecordIcon style={{color: "#43ff00"}}/>
        },
        {
            id: "false",
            name: <FiberManualRecordIcon style={{color: "#ff0000"}}/>
        }
    ];

    return <TextInput value={value} options={options} label={inputLabel} onTextChange={onChange} select>
        {options.map(o => <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>)}
    </TextInput>
}