
import React, {useState} from "react";

import {Button, Grid, Paper} from "@mui/material";
import {GridActionsCellItem} from "@mui/x-data-grid"
import NTMXGrid from "../components/NTMXGrid";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import TextInput from "../components/forms/TextInput";
import dayjs from "dayjs";
import {deletePatient, useGetPatients} from "../services/PatientDataManager";
import PatientModal from "../components/modals/PatientModal";
import ConfirmModal from "../components/modals/ConfirmModal";

export function PatientManager() {

    const patients = useGetPatients() || []
    const [createModal, setCreateModal] = useState(false)
    const [search, setSearch] = useState()
    const [editUser, setEditUser] = useState()
    const [toDelete, setToDelete] = useState()

    let data = patients.slice()

    if(search) data = data.filter(u => u.name.toLocaleLowerCase().includes(search) ||
        u.surname.toLocaleLowerCase().includes(search) || u.fiscalCode.toLocaleLowerCase().includes(search))

    return <Paper style={{padding: "2rem"}}>
        <Grid container justifyContent={"flex-end"} alignItems={"center"}>
            <Grid item sm={4}/>
            <Grid item sm={4}>
                <Button sx={{marginRight: "2rem"}} color={'primary'} startIcon={<AddCircleOutlinedIcon/>} onClick={()  => setCreateModal(true)}>
                    {"Aggiungi nuovo cliente"}
                </Button>
            </Grid>
            <Grid item>
                <TextInput
                        color={"primary"}
                        label="Cerca"
                        variant="outlined"
                        value={search}
                        onTextChange={value => {
                            setSearch(value);
                        }}/>

            </Grid>
        </Grid>
        <NTMXGrid
            disableToolbar
            columns={[
                {
                    field: "id",
                    headerName: "ID Cliente",
                    flex:1,
                },
                {
                    field: "name",
                    headerName: "Nome",
                    flex:1,
                },
                {
                    field: "surname",
                    headerName: "Cognome",
                    flex:1,
                },
                {
                    field: "birthDate",
                    headerName: "Età",
                    flex:1,
                    valueGetter: ({value}) => dayjs().diff(dayjs(value), "year")
                },
                {
                    field: "fiscalCode",
                    headerName: "Codice Fiscale",
                    flex:1
                },
                {
                    field: "anamnesis",
                    headerName: "Anamnesi",
                    flex:1
                },
                {
                    field: 'actions',
                    type: 'actions',
                    getActions: ({row}) => {
                        return [
                            <GridActionsCellItem icon={<ModeEditIcon color={"primary"}/>}
                                                 onClick={() => setEditUser(row)} label="Delete"/>,
                            <GridActionsCellItem icon={<DeleteIcon color={"primary"}/>}
                                                 onClick={() => setToDelete(row)} label="Delete"/>,
                        ];
                    }
                }
            ]}
            rows={data}
            title="Elenco Pazienti"
            getRowId={(row) => patients && row.id}
        />
        <PatientModal open={editUser || createModal} onClose={() => {
            setEditUser(null)
            setCreateModal(false);
        }} patient={editUser}/>
        {toDelete && <ConfirmModal
            open={toDelete} title={"Eliminare?"} saveText={"Elimina"} closeText={"Annulla"}
            onClose={() => setToDelete(null)} onSave={() => deletePatient(toDelete)}
        >
            Vuoi eliminare il cliente {toDelete.name} {toDelete.surname}? Non potrai annullare questa operazione
        </ConfirmModal>}
    </Paper>
}
