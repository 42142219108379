import React, {useState} from "react";

import {
    Button, Card, CardActionArea, CardContent,
    Grid, Stack, Typography
} from "@mui/material";
import {
    deleteDeviceConfiguration,
    useGetConfiguration,
    useGetConfigurationRegisters
} from "../services/DeviceDataManager";
import {useHistory, useParams} from "react-router-dom";
import AddIcon from '@mui/icons-material/AddCircle';
import Container from "@mui/material/Container";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useTheme} from "@mui/material/styles";
import DeviceRegistryModal from "../components/modals/DeviceRegistryModal";
import IconButton from "@mui/material/IconButton";
import {Delete} from "@mui/icons-material";
import DeviceModelModal from "../components/modals/DeviceModelModal";
import ConfirmModal from "../components/modals/ConfirmModal";
import {useSnackbar} from "notistack";
import {getErrorMessage} from "../services/Client";

let RegisterItem = ({id, register, onClick}) => {
    const theme = useTheme();
    let  {name, number, writable} = register

    return <Grid key={id} item> <Card
        sx={{
            backgroundColor:'primary.main',
            borderRadius: 0,
            color:'primary.contrastText',
            '&:hover': {
                bgcolor: theme.palette.secondary.main
            }
        }}>
        <CardActionArea onClick={onClick}>
            <CardContent>
                <Stack direction={"row"} spacing={2}>
                    {writable ? <EditIcon/> : <VisibilityIcon/>}
                    <Typography variant="body2" color="text.light">
                        {number} - {name}
                    </Typography>
                </Stack>
            </CardContent>
        </CardActionArea>
    </Card></Grid>
}


export function ConfigurationDetails() {

    const {cid} = useParams()
    const history = useHistory()
    const {enqueueSnackbar} = useSnackbar()
    const configuration = useGetConfiguration(cid)
    const registers = useGetConfigurationRegisters(cid)
    const [open, setOpen] = useState(false)
    const [edit, setEdit] = useState(false)
    const [toDelete, setToDelete] = useState(false)
    const [register, setRegister] = useState()

    const openModal = register => {
        setOpen(true)
        setRegister(register)
    }

    const closeModal = () => {
        setOpen(false)
        setRegister(undefined)
    }

    const deleteConfig = () => {
        deleteDeviceConfiguration(configuration)
            .then(() => history.goBack())
            .catch(e => {
                enqueueSnackbar(getErrorMessage(e), {variant: "error"})
                setToDelete(false)
            })
    }


    return <Container>
        <Grid container spacing={2} sx={{marginTop:"1rem"}}>
            <Grid itme sm={2}></Grid>
            <Grid item sm={8}>
                <Typography variant="h4" align={"center"}>{configuration?.name}</Typography>
            </Grid>
            <Grid itme sm={2}>
                <IconButton onClick={() => setEdit(true)}><EditIcon color={"primary"}/></IconButton>
                <IconButton onClick={() => setToDelete(true)}><Delete color={"primary"}/></IconButton>
            </Grid>
        </Grid>
        <Grid container spacing={2} sx={{marginTop:"1rem"}}>
            {
                registers?.map(r => <Grid key={r.id} sm={6} item>
                    <RegisterItem id={r.id} onClick={() => openModal(r)} register={r}/>
                </Grid>)
            }
            <Grid item justifyItems={"center"}>
                <Button color={"primary"} startIcon={<AddIcon />} onClick={() => openModal()}>{"Aggiungi"}</Button>
            </Grid>
        </Grid>
        <DeviceRegistryModal open={open} onClose={closeModal} register={register} cid={cid}/>
        <DeviceModelModal open={edit} onClose={() => setEdit(false)} device={configuration}/>
        <ConfirmModal open={toDelete} onClose={() => setToDelete(false)} title={"Elimina"} closeText={"Annulla"}
                      onSave={deleteConfig}
                      saveText={"Elimina"}>
            Confermi l'eliminazione della configurazione? Con essa verranno eliminati tutti i registri creati. <br/>
            ATTENZIONE: Non è possibile eliminare configurazioni a cui sono associate delle macchine
        </ConfirmModal>
    </Container>

}
