import React, {useContext, useEffect} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Sidebar from "../components/layout/Sidebar";
import {setMenu} from "../services/MenuManager";
import {CssBaseline} from "@mui/material";
import Header from "../components/layout/Header";
import {Redirect, Route, Switch} from "react-router-dom";
import Page404 from "../components/pages/Page404";
import {AbilityContext} from "../services/Can";
import UserProfile from "./UserProfile";
import {DeviceManagement} from "./DeviceManagement";
import {DeviceHistory} from "./DeviceHistory";
import {UserManager} from "./UserManager";
import {
    syncronyzeConfigurations,
    syncronyzeDevices
} from "../services/DeviceDataManager";
import {syncronyzeUsers} from "../services/UserDataManager";
import {PatientManager} from "./PatientManager";
import {syncronyzePatients} from "../services/PatientDataManager";
import {abilities, resources} from "../services/ability";
import {ConfigurationManagement} from "./ConfigurationManagement";
import {ConfigurationDetails} from "./ConfigurationDetails";
import {UserContext} from "./App";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        minHeight: '100vh'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

export default function Main() {

    const ability = useContext(AbilityContext);
    const [open, setOpen] = React.useState(false);
    const user = useContext(UserContext);
    const classes = useStyles();

    let menu = setMenu(ability);

    useEffect(() => {
        syncronyzeUsers()
        syncronyzeDevices(user.data)
        syncronyzeConfigurations()
        if(ability.can(abilities.READ, resources.PATIENTS))
            syncronyzePatients()
    }, [])

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <Header menuOpen={open} toggleMenu={setOpen}/>
            <Sidebar open={open} setOpen={setOpen} items={menu}/>

            <main className={classes.content}>
                <div className={classes.toolbar}/>
                <Switch>
                    <Route exact path={"/configurations"}>
                        <ConfigurationManagement/>
                    </Route>
                    <Route exact path={"/devices"}>
                        <DeviceManagement/>
                    </Route>
                    <Route exact path={"/users"}>
                        <UserManager/>
                    </Route>
                    <Route exact path={"/patients"}>
                        <PatientManager/>
                    </Route>
                    <Route path={"/devices/:sn"}>
                        <DeviceHistory/>
                    </Route>
                    <Route path={"/profile/:uid"}>
                        <UserProfile/>
                    </Route>
                    <Route exact path={"/configurations/:cid"}>
                        <ConfigurationDetails/>
                    </Route>
                    <Redirect exact from="/" to="/devices"/>
                    <Route path={"/"}>
                        <Page404/>
                    </Route>

                </Switch>
            </main>
        </div>
    );
}
