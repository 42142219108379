import React from "react";
import Box from "@mui/material/Box";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";

export const CheckBoxContainer = ({value, onChange, fields = []}) => {
	return <Box sx={{
		border: '1px solid white',
		borderRadius:'17px',
		paddingLeft: '14px'
	}}>
		<FormGroup>
			{fields.map(({label, id}) => <FormControlLabel control={<Checkbox
				sx={{color: 'white', '&.Mui-checked':{color:'white'}}} checked={value == id}
				onChange={({target}) => target.checked ? onChange(id) : onChange()} inputProps={{'aria-label': 'controlled'}}/>}
			                                               label={label}/>)}
		</FormGroup>
	</Box>
}
