import React from "react";
import {Link} from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {ListItemAvatar} from "@mui/material";

import {ItemTooltip} from "../forms/CustomTooltip";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		justifyContent: "center",
		padding:theme.spacing(2),
		color:"white",
		'&:hover': {
			color:theme.palette.secondary.main,
			backgroundColor: theme.palette.primary.main
		}
	},
	selected: {
		backgroundColor: theme.palette.secondary.main,
		'&:hover': {
			backgroundColor: theme.palette.secondary.main
		}
	},
	imageIcon: {
		color: theme.palette.primary.contrastText,
		paddingLeft: "5px"
	},
	iconRoot: {
		display: "flex"
	},
	imageText: {
		margin:-10,
		color: theme.palette.primary.contrastText
	}
}));

export default function HoverableListItem({item, open, setOpen = null, selected = false}) {

	let classes = useStyles();

	return (
		<Link to={item.url} style={{textDecoration: "none"}}>
			<ListItem button className={selected && classes.selected}>

				<ItemTooltip title={item.name} enabled={!open}>
					<ListItemAvatar className={classes.imageIcon}>{item.icon}</ListItemAvatar>
				</ItemTooltip>

				{
					open && <ListItemText className={classes.imageText}>{item.name}</ListItemText>
				}
			</ListItem>
		</Link>

	);
}
